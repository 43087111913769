import React from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Heading, Text } from '@chakra-ui/react';

import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';

export const CompareSlider = ({ image1, image2, height }) => {
  return (
    <>
      <ReactCompareSlider
        style={{ maxHeight: height, height: height }}
        handle={
          <ReactCompareSliderHandle
            buttonStyle={{
              backgroundColor: '#212B44',
              color: 'white',
              border: 'none',
            }}
            linesStyle={{ opacity: 1, color: '#212B44', width: '5px' }}
          />
        }
        itemOne={<ReactCompareSliderImage src={image1} srcSet={image1} alt="Image one" />}
        itemTwo={<ReactCompareSliderImage src={image2} srcSet={image2} alt="Image two" />}
      />
      <Box bg="primary" mt={{ md: '5px' }} position="relative">
        <Box
          pos="absolute"
          w="100%"
          height="35px"
          top="0"
          mt={{ base: '-25px', md: '-3.5rem' }}
          display="flex"
          justifyContent="space-between"
        >
          <Box w={{ base: '100px', md: '123px' }} h={{ base: '25px', md: '45px' }} bg="#737373">
            <Center h="100%">
              <Heading color="white" fontSize={{ base: '18px', md: '24px' }} textAlign="center" letterSpacing="2.38px">
                BEFORE
              </Heading>
            </Center>
          </Box>
          <Box w={{ base: '100px', md: '123px' }} h={{ base: '25px', md: '45px' }} bg="primary">
            <Center h="100%">
              <Heading color="white" fontSize={{ base: '18px', md: '24px' }} textAlign="center" letterSpacing="2.38px">
                AFTER
              </Heading>
            </Center>
          </Box>
        </Box>

        <Text textAlign="center" color="white" fontWeight="bold" p={{ base: '0.75rem 2rem', md: 'unset' }}>
          Drag the slider over the image and see the before / after our job is done
        </Text>
      </Box>
    </>
  );
};

CompareSlider.propTypes = {
  image1: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};
