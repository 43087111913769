import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Container, Heading, Image } from '@chakra-ui/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';
SwiperCore.use([Pagination, Autoplay]);

export const SlidingHero = ({ heroHeadline, images }) => {
  const settings = {
    pagination: {
      el: '.projectCard__swiper-pagination',
      type: 'bullets',
    },
  };

  return (
    <Box position="relative">
      <Swiper pagination={settings.pagination} loop autoplay>
        {images.map((image, i) => (
          <SwiperSlide key={i}>
            <Image
              objectFit="cover"
              w="100%"
              h={{ base: '400px', md: '100vh' }}
              maxH={{ md: '800px' }}
              alt={image.image.altText}
              src={image.image.sourceUrl}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        bgGradient="linear(to-t, rgba(0,0,0,0.35) 30%, transparent 100%)"
        zIndex="2"
        position={{ base: 'relative', md: 'absolute' }}
        bottom="0"
        w="100%"
      >
        <Container h="100%">
          <Flex
            flexDirection={{ base: 'column-reverse', md: 'row' }}
            justify="space-between"
            align={{ base: 'flex-start', md: 'flex-end' }}
            bg={{ base: 'primary', md: 'none' }}
            px={{ base: '2rem', xl: '0' }}
            py="2rem"
          >
            <Box p="relative" className="projectCard__swiper-pagination"></Box>
            <Box w={{ base: 'auto', md: '30%' }}>
              {/* 2 Color Heading*/}
              <Heading color="white">{heroHeadline}</Heading>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

SlidingHero.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  heroHeadline: PropTypes.string.isRequired,
};
