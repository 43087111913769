import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text, Container, Heading } from '@chakra-ui/react';

export const SubHeroColumns = ({ heading, secondHeading, body, secondBody }) => {
  return (
    <Container>
      <Flex py="2rem" pt={{ base: '2rem', md: '4rem' }} px={{ base: '2rem', md: '0' }} flexDirection="column">
        <Flex
          flexDirection={{ base: 'column', md: 'unset' }}
          spacing={{ base: 'unset', md: '1rem' }}
          align="flex-start"
          pt="1rem"
        >
          <Box maxW={{ base: '100%', md: '50%' }}>
            <Heading
              maxH={{ base: 'unset', md: '110px' }}
              fontSize={{ base: '21px', md: '32px' }}
              color="primary"
              maxW={{ base: '100%', md: '400px' }}
            >
              {heading}
            </Heading>
            <Text fontSize={{ base: '16px', md: '20px' }} lineHeight="30px" mt="1rem">
              {body}
            </Text>
          </Box>
          <Box
            pl={{ base: 'unset', md: '2rem' }}
            pt={{ base: '1.5rem', md: 'unset' }}
            maxW={{ base: '100%', md: '50%' }}
          >
            <Heading
              maxH={{ base: 'unset', md: '110px' }}
              fontSize={{ base: '21px', md: '32px' }}
              color="primary"
              maxW={{ base: '100%', md: '450px' }}
            >
              {secondHeading}
            </Heading>
            <Text fontSize={{ base: '16px', md: '20px' }} lineHeight="30px" mt="1rem">
              {secondBody}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
};

SubHeroColumns.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  secondHeading: PropTypes.string,
  secondBody: PropTypes.string.isRequired,
};
