import React from 'react';
import { Box, Flex, Icon, Text, HStack, VStack, Container, Image, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { FaLinkedinIn, FaInstagram, FaTwitter, FaFacebookSquare } from 'react-icons/fa';

export const Footer = ({ location, services }) => {
  const { pathname } = location;

  if (pathname === '/') {
    return null;
  }

  return (
    <Box
      background="primary"
      width="100%"
      minHeight="472px"
      color="white"
      paddingLeft="2rem"
      paddingRight={{ md: '2rem' }}
      py="2rem"
    >
      <Container position="relative">
        <Box height="3px" width="100%" bg="secondary" my="1rem" display={{ base: 'block', md: 'none' }}></Box>

        <Box
          height="81px"
          width="81px"
          // bg="white"
          my="2rem"
          position={{ base: 'unset', md: 'absolute' }}
          right={{ base: 'unset', md: '0' }}
          bottom={{ base: 'unset', md: '20px' }}
        >
          <Image src="/images/white-logo.png" h="100%" />
        </Box>

        <VStack spacing="10px" align="flex-start">
          <Text textStyle="pBold">Our Services</Text>
          {services.map((service, i) => (
            <GatsbyLink to={service.url} key={i}>
              <Text textStyle="pFooter">{service.title}</Text>
            </GatsbyLink>
          ))}
        </VStack>

        <Box height="1px" width="100%" bg="white" my="1rem" maxW={{ base: 'unset', md: '365px' }}></Box>

        <VStack align="flex-start">
          <Text textStyle="pBold">Contact Information</Text>
          <Link isExternal href="tel:3058248719" textStyle="pFooter">
            305-824-8719
          </Link>
          <Link isExternal href="mailto:chgc@ch-gc.com" textStyle="pFooter">
            chgc@ch-gc.com
          </Link>
          <Link isExternal href="https://maps.google.com/?q=2319 West 78 Street Hialeah, FL 33016" textStyle="pFooter">
            2319 W 78th St Hialeah,
          </Link>
          <Link isExternal href="https://maps.google.com/?q=2319 West 78 Street Hialeah, FL 33016" textStyle="pFooter">
            FL 33016
          </Link>
        </VStack>

        <Flex
          align={{ base: 'start', md: 'center' }}
          justifyContent={{ base: 'start', md: 'space-between' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <HStack spacing="24px" my="1rem">
            <Link href="/" isExternal>
              <Icon h="20px" w="20px" as={FaInstagram} />
            </Link>
            <Link href="/" isExternal>
              <Icon h="20px" w="20px" as={FaLinkedinIn} />
            </Link>
            <Link href="/" isExternal>
              <Icon h="20px" w="20px" as={FaTwitter} />
            </Link>
            <Link href="/" isExternal>
              <Icon h="20px" w="20px" as={FaFacebookSquare} />
            </Link>
          </HStack>
          <VStack align="flex-start">
            <Text fontSize="10px">ALL RIGHTS RESERVED ® {new Date().getFullYear()} • PRIVACY & POLICY</Text>
            <HStack justify="flex-start">
              <Text fontSize="11px" lineHeight="0.5">
                Design & Development by
              </Text>
              <Link
                isExternal
                href="https://www.creativecoregroup.com"
                fontSize="11px"
                lineHeight="0.5"
                justifyContent="flex-end"
              >
                <strong>CREATIVE</strong>CORE
              </Link>
            </HStack>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};
