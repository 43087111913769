import {
  Box,
  Button,
  Flex,
  Text,
  Heading,
  HStack,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
} from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'gatsby';
import React from 'react';

export const MobileNavbar = ({ services, isResidential, scroll }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuButtonRef = React.useRef();
  return (
    <>
      <Flex
        position="fixed"
        top="0"
        zIndex="5"
        w="100%"
        bg={scroll ? 'primary' : 'transparent'}
        height="70px"
        bgGradient={scroll ? 'none' : 'linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,118,198,0) 80%)'}
        boxShadow={scroll ? '0px 6px 10px 4px #00000026' : 'none'}
      >
        <Flex px="0" w="100%" display="flex" justify="space-between" align="center">
          <Box ml="10px">
            <Link to={isResidential ? '/residential' : '/commercial'}>
              <Image src="/images/white-logo.png" maxH="55px" />
            </Link>
          </Box>

          <HStack spacing="10px">
            <Link to={isResidential ? '/commercial' : '/residential'}>
              <Button
                bg="white"
                size="md"
                color="primary"
                fontSize="15px"
                letterSpacing="1.2px"
                _hover={{ bg: 'secondary', color: 'white' }}
              >
                {isResidential ? 'Commercial' : 'Residential'}
              </Button>
            </Link>
            <IconButton
              ref={menuButtonRef}
              onClick={onOpen}
              bg="primary"
              size="lg"
              maxH="40px"
              borderBottomRightRadius="0"
              borderTopRightRadius="0"
              icon={<GiHamburgerMenu />}
            />
          </HStack>
        </Flex>
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={menuButtonRef} size="full">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton size="lg" mt="10px" />
            <DrawerHeader>
              <Link to={isResidential ? '/commercial' : '/residential'}>
                <Button
                  bg="primary"
                  size="md"
                  color="white"
                  fontSize="15px"
                  letterSpacing="1.2px"
                  _hover={{ bg: 'secondary', color: 'white' }}
                >
                  {isResidential ? 'Go Commercial' : ' Go Residential'}
                </Button>
              </Link>
            </DrawerHeader>

            <DrawerBody>
              <VStack spacing="20px" color="primary" mt="10">
                <Link to={isResidential ? '/residential' : '/commercial'}>
                  <Heading>Home</Heading>
                </Link>
                <Accordion allowToggle>
                  <AccordionItem border="none">
                    <AccordionButton
                      py="0"
                      w="100%"
                      display="flex"
                      justifyContent="center"
                      _hover={{ bg: 'none' }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      <Heading pl="1rem">Services</Heading>
                      <AccordionIcon boxSize="7" />
                    </AccordionButton>

                    {services.map((service, i) => (
                      <AccordionPanel textAlign="center" key={i}>
                        <Link to={service.url}>
                          <Text textStyle="pBold">{service.title}</Text>
                        </Link>
                      </AccordionPanel>
                    ))}
                  </AccordionItem>
                </Accordion>
                <Link to={isResidential ? '/residential/about-us' : '/commercial/about-us'}>
                  <Heading>About</Heading>
                </Link>
                <Link to={isResidential ? '/residential/projects' : '/commercial/projects'}>
                  <Heading>Projects</Heading>
                </Link>
                <Link to={isResidential ? '/residential/contact-us' : '/commercial/contact-us'}>
                  <Heading>Contact</Heading>
                </Link>
              </VStack>
            </DrawerBody>

            <DrawerFooter>{/* Footer */}</DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
