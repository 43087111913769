import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text, Container, Heading } from '@chakra-ui/react';

export const SubHero = ({ heading, body, stacked }) => {
  return (
    <Container>
      <Flex
        py="2rem"
        pt={{ base: '2rem', md: '4rem' }}
        px={{ base: '2rem', md: '0' }}
        flexDirection={stacked ? 'column' : { base: 'column', md: 'row' }}
      >
        <Box
          maxW={stacked ? '100%' : { base: '100%', md: '25%' }}
          pr={{ base: 'unset', md: '3rem' }}
          pb={{ base: !stacked ? '1rem' : 'unset', md: 'unset' }}
        >
          <Heading fontSize="3xl" color="primary" pb={stacked ? '1rem' : 'unset'}>
            {heading}
          </Heading>
        </Box>

        <Box maxW={stacked ? { base: '100%' } : { base: '100%', md: '65%' }}>
          <Text textStyle="p">{body}</Text>
        </Box>
      </Flex>
    </Container>
  );
};

SubHero.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  stacked: PropTypes.bool,
};
