import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Box, Button, Collapse, Flex, Icon, IconButton, Image, Text } from '@chakra-ui/react';
import { VscArrowRight } from 'react-icons/vsc';
import PropTypes from 'prop-types';

export const ServiceCard = ({ image, url, icon, title, description, imagePosition }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Link to={url}>
      <Box
        onMouseEnter={handleToggle}
        onMouseLeave={handleToggle}
        height={{ base: '200px', md: '416px' }}
        width={{ base: '100%', md: '256px' }}
        position="relative"
        boxSizing="border-box"
        backgroundImage={`url(${image})`}
        backgroundSize="cover"
        backgroundPosition={imagePosition ? imagePosition : 'center'}
      >
        <Flex
          height="100%"
          width="100%"
          direction="column"
          justify="flex-end"
          bgGradient="linear(to-t, rgba(0,0,0,0.35) 20%, transparent 30%)"
        >
          <Box padding={{ base: '1.5rem' }}>
            <Image src={icon} maxWidth="60px" maxHeight="50px" my="5px" />
            <Text textStyle="pBold" color="white">
              {title}
            </Text>
            <IconButton
              display={{ base: 'flex', md: 'none' }}
              icon={<VscArrowRight size="20px" />}
              borderRadius="100%"
              position="absolute"
              bottom="1rem"
              right="1rem"
            />
          </Box>
          <Flex bg="rgba(355,355,355,0.9)" display={{ base: 'none', md: 'initial' }} direction="column">
            <Collapse in={show} endingHeight="auto" animateOpacity>
              <Box p="1rem 2rem 1rem 2rem">
                <Text fontSize="15px">{description}</Text>
                <Button variant="primary" mt="1rem" w="100%" justifyContent="flex-start">
                  <Flex
                    h="25px"
                    w="25px"
                    mr="1rem"
                    borderRadius="100%"
                    background="primary"
                    align="center"
                    justify="center"
                  >
                    <Icon as={VscArrowRight} />
                  </Flex>
                  Read More
                </Button>
              </Box>
            </Collapse>
          </Flex>
        </Flex>
      </Box>
    </Link>
  );
};

ServiceCard.propTypes = {
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
