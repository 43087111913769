import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Container, Image, Icon, Heading, Text } from '@chakra-ui/react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';

import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation]);

export const SlimProjectCarousel = ({ projects, heading, body }) => {
  const settings = {
    pagination: {
      el: '.projectCard__swiper-pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.projects__swiper-button-next',
      prevEl: '.projects__swiper-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 1.2,
        spaceBetween: 1,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
  };

  return (
    <Box position="relative" bg="primary" mt="2rem">
      <Container pl={{ base: '2rem', md: 'unset' }} pt="2rem" pb="1.5rem">
        <Heading mb={{ base: '1rem', md: '3rem' }} fontSize="35px" color="white" maxW="325px">
          {heading}
        </Heading>
        <Text pr="2rem" color="white" mb="3rem" textAlign="justify">
          {body}
        </Text>
        <Swiper
          className="projectCarousel_swiper"
          navigation={settings.navigation}
          breakpoints={{
            // when window width is >= 640px
            300: {
              slidesPerView: 1.1,
              spaceBetween: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            // when window width is >= 768px
            1000: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          {projects &&
            projects.map((project) => (
              <SwiperSlide key={project.name}>
                <Box w={{ base: '307px', md: '360px' }} pos="relative" h="450px">
                  <Image src={project} h="100%" w="100%" objectFit="cover" />
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>
        <Flex flexDirection={{ base: 'column', md: 'row' }} mt="2rem" pos="relative">
          <Flex mb={{ base: '1rem', md: 'unset' }}>
            <button className="projects__swiper-button-prev">
              <Icon
                as={AiOutlineArrowLeft}
                bg="secondary"
                w={{ base: '45px', md: '25px' }}
                h={{ base: '45px', md: '25px' }}
                p={{ base: '3', md: '1' }}
                mr="2"
                borderRadius="50%"
                color="white"
              />
            </button>
            <button className="projects__swiper-button-next">
              <Icon
                as={AiOutlineArrowRight}
                bg="secondary"
                w={{ base: '45px', md: '25px' }}
                h={{ base: '45px', md: '25px' }}
                p={{ base: '3', md: '1' }}
                mr="2"
                borderRadius="50%"
                color="white"
              />
            </button>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

SlimProjectCarousel.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
};
