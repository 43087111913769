import React from 'react';
import PropTypes from 'prop-types';
import { Fonts } from './shared';
import { Footer, Navbar } from '../components/shared';

import '../scss/styles.scss';
import { servicesRoutes } from '../routes';
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', { offset: 120, speed: 300 });
}

const Layout = ({ children, location }) => {
  const isResidential = location.pathname.includes('residential');
  const services = isResidential ? servicesRoutes.residential : servicesRoutes.commercial;

  return (
    <>
      <Fonts />
      <Navbar location={location} services={services} isResidential={isResidential} />
      <main>{children}</main>
      <Footer location={location} services={services} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
