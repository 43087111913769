import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { PrimaryButton } from '../../shared';
import React from 'react';
import PropTypes from 'prop-types';

export const ContactUsBanner = ({ image, url, description, heading, altText }) => {
  return (
    <Box
      height={{ base: 'auto', md: '559px' }}
      position="relative"
      bgGradient="linear(to-t, rgba(0,0,0,0.35) 20%, transparent 30%)"
    >
      <Image
        loading="lazy"
        src={image}
        alt={altText}
        objectFit="cover"
        h={{ base: '200px', md: '100%' }}
        w="100%"
        objectPosition="top"
        position={{ md: 'absolute' }}
        zIndex={{ md: '-1' }}
      />
      <Container py={{ md: '3rem' }} height="100%">
        <Flex
          justify={{ md: 'space-between' }}
          align={{ md: 'flex-end' }}
          color="white"
          height="100%"
          direction={{ base: 'column', md: 'row' }}
          bg={{ base: 'primary', md: 'unset' }}
          pl={{ base: '2rem', md: 'unset' }}
          pt={{ base: '2rem', md: 'unset' }}
        >
          <Box maxW={{ md: '500px' }}>
            <Heading textStyle="h2">{heading}</Heading>
            <Text textStyle="p" pr={{ base: '2rem', md: 'unset' }}>
              {description}
            </Text>
          </Box>
          <PrimaryButton url={url}>Contact Us Now</PrimaryButton>
        </Flex>
      </Container>
    </Box>
  );
};

ContactUsBanner.propTypes = {
  heading: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
};
