import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Grid, GridItem, Image } from '@chakra-ui/react';
import { BsChevronDown } from 'react-icons/bs';
import { Link } from 'gatsby';
import React from 'react';

export const DesktopNavbar = ({ services, isResidential, scroll }) => {
  return (
    <Flex
      position="fixed"
      top="0"
      zIndex="5"
      w="100%"
      bg={scroll ? 'primary' : 'transparent'}
      height="80px"
      bgGradient={scroll ? 'none' : 'linear-gradient(180deg, rgba(0,0,0,0.4) 40%, rgba(0,118,198,0) 100%)'}
      boxShadow={scroll ? '0px 6px 10px 4px #00000026' : 'none'}
    >
      <Flex maxW="1177px" px="0" w="100%" display="flex" margin="0 auto" justify="space-between" align="center">
        <Box>
          <Link to={isResidential ? '/residential' : '/commercial'}>
            <Image src="/images/white-logo.png" h="65px" />
          </Link>
        </Box>

        <Grid
          templateColumns="repeat(7, 1fr)"
          gap={2}
          alignItems="center"
          justifyItems="center"
          textStyle="pBold"
          fontSize="15px"
          color="white"
        >
          <Link to={isResidential ? '/residential' : '/commercial'}>Home</Link>
          <Menu textStyle="pBold" fontSize="15px">
            <MenuButton bg="transparent" m="0" textStyle="pBold" fontSize="15px">
              <Flex align="center">
                Services
                <Box mx="0.3rem">
                  <BsChevronDown />
                </Box>
              </Flex>
            </MenuButton>

            <MenuList color="primary" width="30px" border="none">
              {services.map((service, i) => (
                <Link to={service.url} key={i}>
                  <MenuItem textStyle="pBold" fontSize="15px" _hover={{ bg: 'secondary', color: 'white' }}>
                    {service.title}
                  </MenuItem>
                </Link>
              ))}
            </MenuList>
          </Menu>
          <Link to={isResidential ? '/residential/about-us' : '/commercial/about-us'}>About</Link>
          <Link to={isResidential ? '/residential/projects' : '/commercial/projects'}>Projects</Link>
          <Link to={isResidential ? '/residential/contact-us' : '/commercial/contact-us'}>Contact</Link>
          <GridItem colSpan={2}>
            <Link to={isResidential ? '/commercial' : '/residential'}>
              <Button
                bg="white"
                color="primary"
                fontSize="15px"
                letterSpacing="1.2px"
                _hover={{ bg: 'secondary', color: 'white' }}
              >
                {isResidential ? 'Go Commercial' : 'Go Residential'}
              </Button>
            </Link>
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};
