import React, { useState } from 'react';
import { Box, Collapse, Flex, Icon, Text, useMediaQuery } from '@chakra-ui/react';
import { VscArrowUp } from 'react-icons/vsc';
import PropTypes from 'prop-types';

export const SpecialtyServiceCard = ({ image, title, description, fitContainer }) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 500px)');
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Box
      onMouseEnter={!isSmallScreen ? handleToggle : ''}
      onMouseLeave={!isSmallScreen ? handleToggle : ''}
      onClick={isSmallScreen ? handleToggle : ''}
      height={{ base: fitContainer ? '100%' : '444px', md: fitContainer ? '100%' : '416px' }}
      width={{ base: fitContainer ? '100%' : '93%', md: fitContainer ? '100%' : '362px' }}
      position="relative"
      boxSizing="border-box"
      backgroundImage={`url(${image})`}
      backgroundSize="cover"
      backgroundPosition="center"
      clipPath={{ base: 'polygon(93% 0, 100% 7%, 100% 100%, 0 100%, 0 0)', md: 'unset' }}
      cursor="default"
    >
      <Flex
        height="100%"
        width="100%"
        direction="column"
        justify="flex-end"
        bgGradient="linear(to-t, rgba(0,0,0,0.35) 20%, transparent 30%)"
      >
        <Flex padding={{ base: '1.5rem' }} justifyContent="space-between" background="rgba(33, 43, 68, .9)">
          <Text textStyle="pBold" color="white" flex="1">
            {title}
          </Text>
          <Flex
            style={show ? { transition: 'all .3s ease', transform: 'rotate(180deg)' } : { transition: 'all .3s ease' }}
            alignItems="center"
            justifyContent="center"
            bg="secondary"
            borderRadius="100%"
            boxSize={8}
          >
            <Icon as={VscArrowUp} w={5} h={5} color="white" />
          </Flex>
        </Flex>
        <Flex background="rgba(33, 43, 68, .9)" display={{ base: 'initial', md: 'initial' }} direction="column">
          <Collapse in={show} endingHeight="auto" animateOpacity>
            <Box p="0rem 2rem 1rem 2rem">
              <Text color="white">{description}</Text>
            </Box>
          </Collapse>
        </Flex>
      </Flex>
    </Box>
  );
};

SpecialtyServiceCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
