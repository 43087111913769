import React from 'react';
import { Box } from '@chakra-ui/react';

export const TestimonialCard = ({ children, clipped }) => {
  return (
    <Box filter="drop-shadow(0px 10px 20px rgba(50, 50, 0, 0.1))">
      <Box
        bg="white"
        width="full"
        maxWidth="803px"
        minH={{ base: '218px' }}
        clipPath={{
          base: clipped ? 'polygon(93% 0, 100% 13%, 100% 100%, 0 100%, 0 0)' : '',
          md: 'polygon(93% 0, 100% 13%, 100% 100%, 0 100%, 0 0)',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
