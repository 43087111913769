import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Image,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Container,
  Flex,
  Heading,
  Center,
  VStack,
  IconButton,
} from '@chakra-ui/react';

import { CgClose } from 'react-icons/cg';

export const ProjectCard = ({ image, title }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        maxW={{ base: '174px', md: '362px' }}
        height={{ base: '285px', md: '437px' }}
        d="flex"
        flexDirection="column"
        _hover={{ cursor: 'pointer' }}
        onClick={onOpen}
      >
        <Image src={image[0]} objectFit="cover" flex="1" h="100%" w="100%" />
        <Box
          height={{ base: '55px', md: '78px' }}
          bg={{ base: 'white', md: 'primary' }}
          color={{ md: 'white' }}
          justifySelf="flex-end"
          p={{ base: '1rem', md: '1.5rem' }}
        >
          <Text textStyle="pProjectText">{title}</Text>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xxl">
        <ModalOverlay bg="rgba(0, 0, 0, 0.85)" />
        <ModalContent bg="transparent" boxShadow="none">
          <Container>
            <Flex flexDirection={{ base: 'column', md: 'row' }}>
              <Box
                w={{ base: '100%', md: '400px' }}
                bg={{ base: 'primary', md: 'unset' }}
                h={{ base: '70px', md: 'unset' }}
                pos={{ base: 'fixed', md: 'unset' }}
                top={{ base: '0', md: 'unset' }}
                zIndex={{ base: '10', md: 'unset' }}
              >
                <Center
                  pos={{ md: 'fixed' }}
                  top={{ md: '50vh' }}
                  justifyContent={{ base: 'flex-start', md: 'unset' }}
                  h={{ base: '100%', md: 'unset' }}
                  maxW={{ md: '300px' }}
                >
                  <Heading color="white" fontSize={{ base: '20px', md: '35px' }} ml={{ base: '1rem', md: 'unset' }}>
                    {title}
                  </Heading>
                </Center>
              </Box>
              <Flex flex="1" pos="relative" flexDirection="column" alignItems="flex-end">
                <IconButton
                  bg="white"
                  borderRadius="50%"
                  boxSize={{ md: '70px' }}
                  color="primary"
                  icon={<CgClose size="50px" />}
                  pos={{ base: 'fixed', md: 'sticky' }}
                  top={{ base: '10px', md: '20px' }}
                  mr={{ base: '10px', md: '2rem' }}
                  mt={{ md: '2rem' }}
                  zIndex={{ base: '11', md: 'unset' }}
                  justifySelf={{ md: 'flex-end' }}
                  onClick={onClose}
                />
                <VStack spacing="2rem" mt={{ md: '-6rem' }} w="100%">
                  {Array.isArray(image) ? (
                    image.map((img) => <Image src={img} objectFit="cover" w="100%" />)
                  ) : (
                    <Image src={image} objectFit="cover" w="100%" />
                  )}
                </VStack>
              </Flex>
            </Flex>
          </Container>
        </ModalContent>
      </Modal>
    </>
  );
};

ProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
