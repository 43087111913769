import React from 'react';
import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
        @font-face {
            font-family: 'Nexa';
            src: url('../../../fonts/NexaLight.woff2') format('woff2'),
                url('../../../fonts/NexaLight.woff') format('woff'),
                url('../../../fonts/NexaLight.ttf') format('truetype');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Nexa';
            src: url('../../../fonts/NexaBold.woff2') format('woff2'),
                url('../../../fonts/NexaBold.woff') format('woff'),
                url('../../../fonts/NexaBold.ttf') format('truetype');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Mont-Heavy';
            src: url('../../../fonts/Mont-HeavyDEMO.woff2') format('woff2'),
                url('../../../fonts/Mont-HeavyDEMO.woff') format('woff'),
                url('../../../fonts/Mont-HeavyDEMO.ttf') format('truetype');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Lucida-Grande';
            src: url('../../../fonts/LucidaGrande.woff2') format('woff2'),
                url('../../../fonts/LucidaGrande.woff') format('woff'),
                url('../../../fonts/LucidaGrande.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }
    `}
  />
);

export { Fonts };
