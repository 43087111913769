import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { VscArrowRight } from 'react-icons/vsc';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

export const PrimaryButton = ({ children, icon, url, ...props }) => {
  return (
    <Link to={url || '#'}>
      <Button variant="primary" mt="1rem" p="1.5rem 2rem" {...props}>
        <Flex h="25px" w="25px" mr="1rem" borderRadius="100%" background="primary" align="center" justify="center">
          <Icon as={icon || VscArrowRight} />
        </Flex>
        <Text textStyle="pBold">{children}</Text>
      </Button>
    </Link>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.any,
  url: PropTypes.string,
};
