import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Icon, Text, Container, Heading, Button, Image } from '@chakra-ui/react';
import { AiOutlineArrowDown } from 'react-icons/ai';

export const Hero = ({ heroCopy, heroHeadline, image, ctaText, icon, noCta, noIcon, fullHeight, mobilePosition }) => {
  return (
    <Box position="relative">
      <Flex height={{ base: fullHeight ? '100vh' : '550px', md: 'unset' }} direction={{ base: 'column', md: 'unset' }}>
        <Image
          objectFit="cover"
          objectPosition={mobilePosition ? mobilePosition : 'center'}
          w="100%"
          h={{ base: '300px', md: '100vh' }}
          maxH={{ md: '800px' }}
          src={image}
          flex={{ base: '1', md: 'unset' }}
        />
        <Box
          position={{ base: 'relative', md: 'absolute' }}
          bottom="0"
          w="100%"
          bgGradient="linear(to-t, rgba(0,0,0,0.5) 30%, transparent 100%)"
        >
          <Container h="100%">
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              justify="space-between"
              align={{ base: 'flex-start', md: 'flex-end' }}
              py={{ base: '2rem', md: '2rem' }}
              bg={{ base: 'primary', md: 'none' }}
              pl={{ base: '2rem', xl: '0' }}
              color="white"
            >
              <Box maxW="650px">
                {!noIcon && <Image src={icon} h="65px" width="65px" pb={{ base: '8px', md: 'unset' }} />}
                <Heading>{heroHeadline}</Heading>
                <Text
                  fontSize={{ base: 'md', md: 'xl' }}
                  fontWeight={{ base: 'bold', md: 'unset' }}
                  pb={{ base: '2', md: '0' }}
                >
                  {/* 2 Color Heading*/}
                  {heroCopy}
                </Text>
              </Box>
              {!noCta && (
                <Button as="a" href="#contact_form" size="lg">
                  <Icon
                    as={AiOutlineArrowDown}
                    bg="primary"
                    boxSize="30px"
                    p="1"
                    mr="2"
                    borderRadius="50%"
                    color="white"
                  />
                  {ctaText}
                </Button>
              )}
            </Flex>
          </Container>
        </Box>
      </Flex>
    </Box>
  );
};

Hero.propTypes = {
  icon: PropTypes.string,
  heroCopy: PropTypes.string.isRequired,
  heroHeadline: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  noCta: PropTypes.bool,
  noIcon: PropTypes.bool,
  fullHeight: PropTypes.bool,
};
