import React from 'react';
import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '../../shared';

export const MainBanner = ({ ctaUrl, image, title, description, noCta }) => {
  return (
    <Box bg="primary" color="white" height="auto" my={{ base: 'unset', lg: '5rem' }}>
      <Box height={{ base: 'auto', lg: '487px' }}>
        <Container>
          <Flex direction={{ base: 'column', lg: 'row' }}>
            <Image
              src={image}
              height={{ base: '163px', lg: '514px' }}
              width={{ base: '100%', lg: '666px' }}
              objectFit="cover"
              objectPosition="center"
              mt={{ base: 'unset', lg: '5rem' }}
            />
            <Flex p="2rem" height={{ lg: '487px' }} direction="column" justify="center" align="flex-start">
              <Heading textStyle="h2" my="1rem">
                {title}
              </Heading>
              <Text textStyle="p">{description}</Text>
              {!noCta && (
                <PrimaryButton url={ctaUrl} display={{ base: 'none', lg: 'flex' }}>
                  See all projects
                </PrimaryButton>
              )}
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

MainBanner.propTypes = {
  image: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string,
  noCta: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
