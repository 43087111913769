import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@chakra-ui/react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';

export const SimpleImageCarousel = ({ images }) => {
  return (
    <Swiper
      activeSlideKey="2"
      slidesPerView={1.7}
      spaceBetween={5}
      loop={true}
      autoplay={{ delay: 3000 }}
      centeredSlides={true}
    >
      {images &&
        images.map((image) => (
          <SwiperSlide key={image}>
            <Image w="240px" h="367px" objectFit="cover" src={image} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

SimpleImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};
