import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Container, Icon, Heading } from '@chakra-ui/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';

import 'swiper/swiper-bundle.css';
import { ProjectCardModal } from '../Cards/ProjectCardModal';
SwiperCore.use([Pagination, Navigation]);

export const ProjectCarousel = ({ projects, isResidential }) => {
  const settings = {
    pagination: {
      el: '.projectCard__swiper-pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.projects__swiper-button-next',
      prevEl: '.projects__swiper-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 1.2,
        spaceBetween: 1,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
  };

  return (
    <Box position="relative" mb={{ base: '5rem' }}>
      <Container pl={{ base: '2rem', md: 'unset' }}>
        <Heading mb={{ base: '1rem', md: '3rem' }} textStyle="" fontSize="35px" color="primary">
          Projects
        </Heading>
        <Swiper
          className="projectCarousel_swiper"
          pagination={settings.pagination}
          navigation={settings.navigation}
          breakpoints={{
            // when window width is >= 640px
            300: {
              slidesPerView: 1.1,
              spaceBetween: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            1000: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          {projects &&
            projects.slice(0, 3).map((project, index) => (
              <SwiperSlide key={index}>
                <ProjectCardModal
                  image={project.projectGallery}
                  title={project.projectName}
                  location={project.projectLocation}
                  wide
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <Flex flexDirection={{ base: 'column', md: 'row' }} zIndex="2" pos="relative" mt="2rem">
          <Flex mb={{ base: '1rem', md: 'unset' }}>
            <button className="projects__swiper-button-prev">
              <Icon
                as={AiOutlineArrowLeft}
                bg="secondary"
                w={{ base: '45px', md: '25px' }}
                h={{ base: '45px', md: '25px' }}
                p={{ base: '3', md: '1' }}
                mr="2"
                borderRadius="50%"
                color="white"
              />
            </button>
            <button className="projects__swiper-button-next">
              <Icon
                as={AiOutlineArrowRight}
                bg="secondary"
                w={{ base: '45px', md: '25px' }}
                h={{ base: '45px', md: '25px' }}
                p={{ base: '3', md: '1' }}
                mr="2"
                borderRadius="50%"
                color="white"
              />
            </button>
          </Flex>

          <Box ml={{ base: 'unset', md: '1.5rem' }} p="relative" className="projectCard__swiper-pagination"></Box>
        </Flex>
      </Container>
      <Box w="100%" h="550px" top={{ base: '7rem', md: '12rem' }} position="absolute" bg="primary"></Box>
    </Box>
  );
};

ProjectCarousel.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
};
