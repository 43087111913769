import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { DesktopNavbar } from './DesktopNavbar';
import { MobileNavbar } from './MobileNavbar';

export const Navbar = (props) => {
  const [scroll, setScroll] = useState(false);

  const toggleScroll = () => {
    if (window.scrollY >= 80) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', toggleScroll);
  }

  if (props.location.pathname === '/') {
    return null;
  }

  return (
    <>
      <Box display={{ base: 'none', md: 'block' }}>
        <DesktopNavbar {...props} scroll={scroll} />
      </Box>
      <Box display={{ base: 'block', md: 'none' }}>
        <MobileNavbar {...props} scroll={scroll} />
      </Box>
    </>
  );
};
