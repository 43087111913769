export const servicesRoutes = {
  residential: [
    {
      title: 'New Construction',
      url: '/residential/services/new-construction',
    },
    {
      title: 'Windows & Doors',
      url: '/residential/services/windows-and-doors',
    },
    {
      title: 'Handyman Work',
      url: '/residential/services/handyman-work',
    },
    {
      title: 'Remodeling',
      url: '/residential/services/remodeling',
    },
  ],
  commercial: [
    {
      title: 'Capital Projects',
      url: '/commercial/services/capital-projects',
    },
    {
      title: 'Maintenance',
      url: '/commercial/services/maintenance',
    },
    {
      title: 'Carpentry',
      url: '/commercial/services/carpentry',
    },
    {
      title: 'Painting',
      url: '/commercial/services/painting',
    },
  ],
};
