import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Flex, Heading, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import { CommercialContactForm, ContactForm } from '../';

export const ContactUsBannerWithForm = ({ title, description, lightBackground, image, isCommercial }) => {
  return (
    <Box height={{ base: 'auto', md: '473px' }} position="relative" mb={{ base: '2rem', md: '0' }}>
      <Image
        src={image}
        objectFit="cover"
        h={{ base: '538px', md: '100%' }}
        w="100%"
        objectPosition="left"
        position="absolute"
        zIndex="-1"
      />
      <Container py={{ md: '3rem' }} height="100%">
        <Flex height="100%" align="center" direction={{ base: 'column', md: 'row' }} pl={{ base: '2rem', md: 'unset' }}>
          <VStack
            flex={{ md: '1' }}
            minWidth="320px"
            spacing="10px"
            color={!lightBackground ? 'white' : 'primary'}
            align="flex-start"
          >
            <Heading textStyle="h2" mt={{ base: '3rem', md: 'unset' }}>
              {title}
            </Heading>
            <Text textStyle="pFooter" maxW="450px" mb="1rem" pr={{ base: '2rem', md: 'unset' }}>
              {description}
            </Text>
            <HStack
              spacing={{ md: '2rem' }}
              flexDirection={{ base: 'column', md: 'row' }}
              justify={{ base: 'flex-start', md: 'unset' }}
              pl={{ base: '0.5rem', md: 'unset' }}
              w={{ base: '100%', md: 'unset' }}
            >
              <HStack spacing="0.75rem" w={{ base: '100%', md: 'unset' }} pl={{ base: '0.1rem', md: 'unset' }}>
                <Image src="/icons/call-us.svg" filter={lightBackground ? '' : 'brightness(0) invert(1)'} />
                <Link href="tel:3058248719" fontWeight="bold">
                  305-824-8719
                </Link>
              </HStack>
              <HStack
                spacing="0.5rem"
                pt={{ base: '1rem', md: 'unset' }}
                w={{ base: '100%', md: 'unset' }}
                display={isCommercial ? 'none' : 'flex'}
              >
                <Image src="/icons/icon-financing.svg" filter={lightBackground ? '' : 'brightness(0) invert(1)'} />
                <Text fontWeight="bold" lineHeight="1.4">
                  Financing Available
                </Text>
              </HStack>
            </HStack>
          </VStack>
          <Flex flex={{ md: '1' }} justify="flex-end" width={{ base: '100%', md: 'unset' }}>
            <Box
              minHeight={{ base: '300px' }}
              width={{ base: '100%', md: '477px' }}
              bg="white"
              borderRadius="4px"
              mt={{ base: '2rem', md: '-13rem' }}
              boxShadow="11px 14px 22px 0 rgba(0, 0, 0, 0.16)"
              p="2rem"
            >
              {isCommercial ? <CommercialContactForm /> : <ContactForm />}
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

ContactUsBannerWithForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  lightBackground: PropTypes.bool,
};
