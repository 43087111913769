import React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Textarea,
  Icon,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { AiOutlineArrowRight } from 'react-icons/ai';

export const ContactForm = ({ services }) => {
  const ContactValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    message: Yup.string().required('Message is required'),
    phone: Yup.string(),
    address: Yup.string(),
  });

  return (
    <Formik
      initialValues={{ firstName: '', lastName: '', email: '', address: '', phone: '', message: '' }}
      validationSchema={ContactValidationSchema}
      onSubmit={(values, actions) => {
        const getFormData = Object.keys(values).reduce((formData, key) => {
          formData.append(key, values[key]);
          return formData;
        }, new FormData());
        fetch('https://wordpress.chglobalconstruction.com/wp-json/chglobal/v1/request-contact-information', {
          method: 'POST',
          body: getFormData,
        })
          .then((res) => {
            actions.setSubmitting(false);
            actions.resetForm();
            if (res.status === 200) {
              actions.setStatus({
                success: 'Thank you for your interest in our services. A representative will contact you shortly.',
              });
            } else {
              actions.setStatus({
                success: 'An error has occurred. Please try again later or email us at info@chglobal.com',
              });
            }
          })
          .catch((err) => {
            actions.setSubmitting(false);
            actions.setStatus({
              success: 'An error has occurred. Please try again later or email us at info@chglobal.com',
            });
          });
      }}
    >
      {(props) => (
        <Form id="contact_form">
          <Grid templateColumns="repeat(2, 1fr)" gap={4} color="primary">
            <GridItem colSpan={{ base: '2', md: '1' }}>
              <Field name="firstName">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.firstName && form.touched.firstName} isRequired>
                    <FormLabel htmlFor="firstName" lineHeight="1">
                      First name:
                    </FormLabel>
                    <StyledInput {...field} id="firstName" type="text" />
                  </FormControl>
                )}
              </Field>
            </GridItem>

            <GridItem colSpan={{ base: '2', md: '1' }}>
              <Field name="lastName">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.lastName && form.touched.lastName} isRequired>
                    <FormLabel htmlFor="lastName" lineHeight="1">
                      Last name:
                    </FormLabel>
                    <StyledInput {...field} id="lastName" type="text" />
                  </FormControl>
                )}
              </Field>
            </GridItem>

            <GridItem colSpan={2}>
              <Field name="address">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.address && form.touched.address}>
                    <FormLabel htmlFor="address" lineHeight="1">
                      Address:
                    </FormLabel>
                    <StyledInput {...field} id="address" />
                    <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>

            <GridItem colSpan={2}>
              <Field name="phone">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                    <FormLabel htmlFor="phone" lineHeight="1">
                      Phone number:
                    </FormLabel>
                    <StyledInput {...field} id="phone" type="tel" />
                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>

            <GridItem colSpan={2}>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.email && form.touched.email} isRequired>
                    <FormLabel htmlFor="email" lineHeight="1">
                      Email:
                    </FormLabel>
                    <StyledInput {...field} id="email" type="email" />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>

            <GridItem colSpan={2}>
              <Field name="message">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.message && form.touched.message} isRequired>
                    <FormLabel htmlFor="message" lineHeight="1">
                      Message:
                    </FormLabel>
                    <StyledTextArea {...field} id="message" />
                  </FormControl>
                )}
              </Field>
            </GridItem>

            {props.status ? (
              <GridItem colSpan={2}>
                <Text align="center" textStyle="p" fontWeight="bold" lineHeight="1">
                  {props.status.success}
                </Text>
              </GridItem>
            ) : (
              <GridItem>
                <Button
                  type="submit"
                  alignSelf="flex-start"
                  fontSize="17px"
                  isLoading={props.isSubmitting}
                  disabled={!props.dirty || props.isSubmitting}
                  _hover={{ bg: 'primary' }}
                >
                  <Icon
                    as={AiOutlineArrowRight}
                    bg="primary"
                    boxSize="22px"
                    p="1"
                    mr="2"
                    borderRadius="50%"
                    color="white"
                  />
                  Submit
                </Button>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const StyledInput = styled(Input)`
  border-radius: 6px;
  border-color: #c7c7c7;
  border-width: 2px;
  height: 50px;

  &:focus {
    border-color: #212b44 !important;
  }
`;

const StyledTextArea = styled(Textarea)`
  border-radius: 6px;
  border-color: #c7c7c7;
  border-width: 2px;
  height: 100px;

  &:focus {
    border-color: #212b44 !important;
  }
`;
