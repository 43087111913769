import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text, Container, Heading, Image, Stack, VStack } from '@chakra-ui/react';

export const SubHeroIcons = ({ heading, body, iconSection }) => {
  return (
    <Container>
      <Flex py="2rem" px={{ base: '2rem', md: '0' }} flexDirection={{ base: 'column', md: 'row' }}>
        <Box maxW={{ base: '100%', md: '25%' }} pr="3rem">
          <Heading fontSize={{ base: '28px', md: '3xl' }} color="primary" pb={{ base: '1rem', md: 'unset' }}>
            {heading}
          </Heading>
        </Box>
        <Box maxW={{ base: '100%', md: '70%' }}>
          <Text fontSize="20px" textStyle="p" lineHeight="1.5">
            {body}
          </Text>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
            borderBottom="1px"
            borderColor="primary"
            pt={{ base: '1rem', md: '2.5rem' }}
            pb="1rem"
            // display={{ base: 'none', md: 'flex' }}
          >
            <Flex justifyContent={{ base: 'center', md: 'space-between' }} w="100%">
              {iconSection.map((iconblock, i) => (
                <>
                  {/* Desktop */}
                  <Flex
                    align="flex-end"
                    mr={`${i === iconSection.length - 1 ? '1.5rem' : 'unset'}`}
                    display={{ base: 'none', md: 'flex' }}
                  >
                    <Image src={iconblock.icon} boxSize="30px" />
                    <Text fontSize="16px" fontWeight="bold" color="primary" pl={{ base: '2', md: '3' }} lineHeight="1">
                      {iconblock.title}
                    </Text>
                  </Flex>

                  {/* Mobile */}
                  <Flex mx="0.5rem" display={{ base: 'flex', md: 'none' }}>
                    <VStack w="80px">
                      <Image src={iconblock.icon} boxSize="45px" />
                      <Text fontSize="11px" fontWeight="bold" color="primary" lineHeight="1" textAlign="center">
                        {iconblock.title}
                      </Text>
                    </VStack>
                  </Flex>
                </>
              ))}
            </Flex>
          </Stack>
        </Box>
      </Flex>
    </Container>
  );
};

SubHeroIcons.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  iconSection: PropTypes.arrayOf(PropTypes.object).isRequired,
};
