import React from 'react';
import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '../../shared';

export const MainServiceBanner = ({ ctaUrl, image, title, description, noCta }) => {
  return (
    <Box bg="primary" color="white" height="auto" my={{ base: '3rem', lg: '5rem' }}>
      <Box height={{ base: 'auto', lg: '487px' }}>
        <Container>
          <Flex direction={{ base: 'column', lg: 'row' }} position="relative" pt={{ base: '7.5rem', lg: 'unset' }}>
            <Image
              src={image}
              height={{ base: '200px', lg: '514px' }}
              width={{ base: '100%', lg: '666px' }}
              objectFit="cover"
              objectPosition="center"
              mt={{ base: '-3rem', lg: '5rem' }}
              position={{ base: 'absolute', lg: 'unset' }}
              top={{ base: '0', lg: 'unset' }}
              pl={{ base: '2rem', lg: ' unset' }}
              clipPath={{ base: 'polygon(96% 0, 100% 8%, 100% 100%, 0 100%, 0 0)', md: 'unset' }}
            />
            <Flex
              pl="3rem"
              py="2rem"
              px={{ base: '2rem', md: 'unset' }}
              p={{ base: '2rem', md: '2rem 0 2rem 3rem' }}
              height={{ lg: '487px' }}
              direction="column"
              justify="center"
              align="flex-start"
            >
              <Heading fontSize={{ base: '35px', md: '35px' }} mt="1rem" mb="0.25rem">
                {title}
              </Heading>
              <Text textStyle="p">{description}</Text>
              {!noCta && (
                <PrimaryButton url={ctaUrl} display={{ base: 'none', lg: 'flex' }}>
                  See all projects
                </PrimaryButton>
              )}
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

MainServiceBanner.propTypes = {
  image: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string,
  noCta: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
