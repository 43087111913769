import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text, Container, Heading, Image, VStack } from '@chakra-ui/react';
import { CommercialContactForm, ContactForm } from '../Forms';

export const ContactHero = ({ image, contactBlurb, isCommercial = false }) => {
  return (
    <Box position="relative">
      <Image objectFit="cover" w="100%" h={{ base: '300px', md: '800px' }} src={image} objectPosition="cover" />
      <Box
        position={{ base: 'relative', md: 'absolute' }}
        bottom="0"
        w="100%"
        bgGradient="linear(to-t, rgba(0,0,0,0.35) 20%, transparent 40%)"
      >
        <Container h="100%" mt={{ base: '-3rem', md: 'unset' }}>
          <Flex justify="space-between" align="flex-end">
            <Box display={{ base: 'none', md: 'block' }} w="40%">
              <Heading color="white">Contact Us</Heading>
              <Text fontSize={{ base: 'large' }} color="white" pb="2rem">
                {contactBlurb}
              </Text>
            </Box>
            <Box
              borderRadius="8px"
              boxShadow="md"
              mb={{ base: '-10px', md: '-4rem' }}
              px="3rem"
              py="3rem"
              w={{ base: '100%', md: '500px' }}
              bg="white"
              minH="722px"
            >
              <VStack spacing="10px" align="flex-start" pb="20px">
                <Heading lineHeight="1" color="primary">
                  Send us a <br></br> message
                </Heading>
                <Text textStyle="p" display={{ base: 'block', md: 'none' }}>
                  {contactBlurb}
                </Text>
              </VStack>

              {isCommercial ? <CommercialContactForm /> : <ContactForm />}
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

ContactHero.propTypes = {
  image: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  contactBlurb: PropTypes.string.isRequired,
};
